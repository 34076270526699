import { useState, useLayoutEffect} from 'react';
import styles from '../styles/body.module.scss'
import {Box, TextField} from '@mui/material';
import useAxios from "../hooks/useAxios";
import axios from "../api/axios";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormControl from '@mui/material/FormControl';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function Site({reportType, reportCategory, setReportCategory, dateError, setDateError,timeDate, setTimeDate, 
    siteSelection, setSite, contactNumber, setContactNumber, setAdminID, isGoodPractice, setIsGoodPractice}){
    const navigate = useNavigate();
    function goBack(){
        setAdminID('');
        navigate(-1);
    }

    const [displaySection, setDisplaySection] = useState(false);
    const [displayGPSection, setDisplayGPSection] = useState(false);


    const [gpError, setGPError] = useState(false);
    const [gpText, setGPText] = useState('');
    const [siteError, setSiteError] = useState(false);
    const [numberError, setNumberError] = useState(false);
    const [dateTimeText, setDateTimeText] = useState('');
    const [siteText, setSiteText] = useState('');
    const [categoryError, setCategoryError] = useState(false);
    const [categoryText, setCategoryText] = useState('');
    const [numberText, setNumberText] = useState('');
    var phone_number_regex =/^\+?([0-9]\s*){11,17}$/;

    useLayoutEffect(() => {
        if(reportType != "Accident/Injury"){
          setDisplaySection(true);
        }
    }, [reportType])

    useLayoutEffect(() => {
        if(reportType === "Observation"){
          setDisplayGPSection(true);
        }
    }, [reportType])

    const [category] = useAxios({
        axiosInstance:axios,
        method:'get',
        url:'/allCategories'
    });
    
    const [site] = useAxios({
        axiosInstance:axios,
        method:'get',
        url:'/allSites'
    });

   

    const handleTimeDateChange = (newValue) => {
        setTimeDate(newValue);
    };

    const handleSiteChange = (event, newValue) => {
        setSite(newValue);
    };

    const handleCategoryChange = (event, newValue) => {
        setReportCategory(newValue)
    };

    const handleContactNumberChange = (newValue) => {
        setContactNumber(newValue);
    };

    const handleIsGoodPracticeChange = (e) =>{
        setIsGoodPractice(e.target.value);
    }

    const observationCheck = ()=>{
        if(((timeDate !==null && timeDate.toString() !== "" && timeDate.toString() !== "Invalid Date")&&!dateError)  && siteSelection !== null && reportCategory !== null && isGoodPractice !== null ){

            setDateTimeText('');
            setSiteText('');
            setNumberText('');
            setCategoryText('')
            setGPText('');
            setGPError(false)
            setDateError(false);
            setSiteError(false);
            setNumberError(false);
            setCategoryError(false);

            if(contactNumber.length > 0 && !phone_number_regex.test(contactNumber)){
                setNumberText('Please provide a valid contact phone number');
                setNumberError(true);
            }else{
                console.log("Bravo");
                navigate("/who_involved")

            }
        }
        else{

            setDateTimeText('');
            setSiteText('');
            setNumberText('');
            setCategoryText('')
            setGPText('');
            setGPError(false)
            setDateError(false);
            setSiteError(false);
            setNumberError(false);
            setCategoryError(false);
            if(timeDate === null || timeDate.toString() === "Invalid Date"|| timeDate.toString() === ""){
                setDateTimeText('Please provide a date and time');
                setDateError(true);
            }if(siteSelection === null){
                setSiteText('Please select a site');
                setSiteError(true);
            }
            if(reportCategory === null){
                setCategoryText('Please select a subcategory');
                setCategoryError(true);
            }
            if(isGoodPractice === null){
                setGPText('Please select an option');
                setGPError(true);
            }
            
        }
    }

    const nearMissCheck = ()=>{
        if(((timeDate !==null && timeDate.toString() !== "" && timeDate.toString() !== "Invalid Date")&&!dateError)  && siteSelection !== null && reportCategory !== null ){

            setDateTimeText('');
            setSiteText('');
            setNumberText('');
            setCategoryText('')
            setDateError(false);
            setSiteError(false);
            setNumberError(false);
            setCategoryError(false);

            if(contactNumber.length > 0 && !phone_number_regex.test(contactNumber)){
                setNumberText('Please provide a valid contact phone number');
                setNumberError(true);
            }else{
                console.log("Bravo");
                navigate("/who_involved")

            }
        }
        else{

            setDateTimeText('');
            setSiteText('');
            setNumberText('');
            setCategoryText('')
            setDateError(false);
            setSiteError(false);
            setNumberError(false);
            setCategoryError(false);
            if(timeDate === null || timeDate.toString() === "Invalid Date"|| timeDate.toString() === ""){
                setDateTimeText('Please provide a date and time');
                setDateError(true);
            }if(siteSelection === null){
                setSiteText('Please select a site');
                setSiteError(true);
            }
            if(reportCategory === null){
                setCategoryText('Please select a subcategory');
                setCategoryError(true);
            }
            
        }
    }

    const accidentInjuryCheck = ()=>{
        if(((timeDate !==null && timeDate.toString() !== "" && timeDate.toString() !== "Invalid Date")&&!dateError)  && siteSelection !== null ){

            setDateTimeText('');
            setSiteText('');
            setNumberText('');
            setCategoryText('')
            setDateError(false);
            setSiteError(false);
            setNumberError(false);
            setCategoryError(false);

            if(contactNumber.length > 0 && !phone_number_regex.test(contactNumber)){
                setNumberText('Please provide a valid contact phone number');
                setNumberError(true);
            }else{
                navigate("/who_involved")

            }
        }
        else{

            setDateTimeText('');
            setSiteText('');
            setNumberText('');
            setCategoryText('')
            setDateError(false);
            setSiteError(false);
            setNumberError(false);
            setCategoryError(false);
            if(timeDate === null || timeDate.toString() === "Invalid Date"|| timeDate.toString() === ""){
                setDateTimeText('Please provide a date and time');
                setDateError(true);
            }if(siteSelection === null){
                setSiteText('Please select a site');
                setSiteError(true);
            }
            
        }
    }



    const nextPage = () => {
        if(reportType === 'Observation'){
            observationCheck();
        }
        else if(reportType === 'Near Miss'){
            nearMissCheck();
        }
        else if(reportType === 'Accident/Injury'){
            accidentInjuryCheck();
        }
        

    }
   const exceptThisSymbols = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9","Backspace", " "];

  return (
      <>
          {displayGPSection ?
              <>
                  <Box className={styles.questionH1Top} >
                      <h1>Is this good practice?</h1>
                  </Box>
                  <Box className={styles.radioGroup}>
                      <FormControl sx={{ m: 3 }} error={gpError} variant="standard">
                          <RadioGroup
                              row
                              aria-labelledby="demo-error-radios"
                              name="row-radio-buttons-group"
                              value={isGoodPractice}
                              onChange={handleIsGoodPracticeChange}
                          >
                              <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.3rem' } }} value={true} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }} />} label="Yes" />
                              <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.3rem' } }} value={false} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }} />} label="No" />

                          </RadioGroup>
                          <FormHelperText>{gpText}</FormHelperText>
                      </FormControl>
                  </Box>
              </>
              :
              <></>}

          {displaySection ?
        <>
		<Box className={styles.questionH1} >
              <h1>What is this report about?</h1>
          </Box>
          <Box className={styles.textField} >
              <FormControl fullWidth margin="normal" className={styles.field} error={categoryError}>
                  <Autocomplete
                      disablePortal
                      disableClearable
                      value={reportCategory}
                      onChange={handleCategoryChange}
                      options={category}
                      getOptionLabel={(category) => category.categoryName}
                      renderInput={(params) => (
                          <TextField {...params} label="Subcategory" error={categoryError} />
                      )}
                  />
                  <FormHelperText>{categoryText}</FormHelperText>
              </FormControl>
          </Box>
		 </>
        :
        <></>}
          <Box className={styles.questionH1Top} >
              <h1>When did this happen?</h1>
          </Box>
          <Box className={styles.textField} >
              <FormControl fullWidth
                  margin="normal"
                  className={styles.field}
                  error={dateError}
              >
                  <DateTimePicker
                      error={dateError}
                      label="Date and Time"
                      value={timeDate}
                      ampm={false}
                      disableFuture={true}
                      inputFormat="dd/MM/yyyy HH:mm"
                      onChange={handleTimeDateChange}
                      onError={(reason, value) => {
                          if (reason) {
                              setDateTimeText("Please provide a date and time");
                              setDateError(true);
                          } else {
                              setDateTimeText(null);
                              setDateError(false);
                          }
                      }}
                      renderInput={(params) => <TextField {...params} />}
                  />
                  <FormHelperText>{dateTimeText}</FormHelperText>
              </FormControl>
          </Box>
          <Box className={styles.questionH1} >
              <h1>Where did this take place?</h1>
          </Box>
          <Box className={styles.textField} >
              <FormControl fullWidth margin="normal" className={styles.field} error={siteError}>
                  <Autocomplete
                      disablePortal
                      disableClearable
                      value={siteSelection}
                      onChange={handleSiteChange}
                      options={site}
                      getOptionLabel={(site) => site.siteName}
                      renderInput={(params) => (
                          <TextField {...params} label="Sites" error={siteError} />
                      )}
                  />
                  <FormHelperText>{siteText}</FormHelperText>
              </FormControl>
          </Box>
          <Box className={styles.questionH1} >
              <h1>Contact phone number</h1>
          </Box>
          <Box className={styles.textField} >
              <FormControl fullWidth margin="normal" className={styles.field} error={numberError}>
                  <TextField error={numberError} value={contactNumber} type="tel" pattern="[0-9]*"
                      onKeyDown={e => !exceptThisSymbols.includes(e.key) && e.preventDefault()}
                      fullWidth id="outlined-basic" label="Contact Phone Number (Optional)" variant="outlined" onChange={e => handleContactNumberChange(e.target.value)} />
                  <FormHelperText>{numberText}</FormHelperText>
              </FormControl>
          </Box>
          <Box className={btnStyles.navbuttons} >
              <Button startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button endIcon={<ArrowForwardIosIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height: '70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<ArrowForwardIosIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>

      </>
      
  )
}
