import { useState, useLayoutEffect } from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import UploadIcon from '@mui/icons-material/Upload';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgressWithLabel from '../components/LinearProgressWithLabel';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'

export default function Riddor({ reportType, observationType, fuelSpillValue, measureControlValue,
  timeDate, siteSelection, contactNumber, supplierSelection, professionSelection, workingArea,
  supplierTextField, witnessed, checked, age, body_part_injured, severity_of_injury, imgFile,
  weather, lighting, underfoot_condition, notes, accidentSummary, nameOfCompleting, riddor, setRiddor, file,
  setFile,setReportType, setObservationType, setFuelSpillValue, setMeasureControlValue, 
  setTimeDate, setSiteSelection, setContactNumber, setSupplierSelection, setProfessionSelection,  
  setWorkingArea, setSupplierTextField, setWitnessed, setChecked, setAge, setBody_part_injured, setSeverity_of_injury,
   setImgFile, setWeather, setLighting, setUnderfoot_condition, setNotes, setAccidentSummary, setNameOfCompleting, namesOfPplInvolved, setNamesOfPplInvolved, setShowTextField,
   memberOfThePublic, setMemberOfThePublic, sendSupplierEmail, setSendSupplierEmail, reportCategory, setReportCategory,
   isSiteTeam, setIsSiteTeam,  address1, setAddress1, address2, setAddress2,
   city, setCity, county, setCounty, postcode, setPostcode, consentToShare, setConsentToShare,  injuredIndividual, setInjuredIndividual, notOnSite, setNotOnSite,
    accidentActionTaken, setAccidentActionTaken, setShowNametextField,
    generateLink, setGenerateLink, reportReviewLink, setReportReviewLink, setReportID,
    adminID, setAdminID, adminFirstName, setAdminFirstName, adminLastName, setAdminLastName, isGoodPractice, setIsGoodPractice
  
  }) {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [response, error2,loading, axiosFetch] = useAxiosFunction();

  const [error, setError] = useState(false);
  const [riddorText, setRiddorText] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [emailText, setEmailText] = useState('');

  const [generateLinkError, setGenerateLinkError] = useState(false);
  const [generateLinkText, setGenerateLinkText] = useState('');

  const [result, setResult] = useState(false);
  const [imgResult, setImgResult] = useState(false);

  const [progress, setProgress] = useState(false);
  const [lineProgress, setLineProgress] = useState(0);

  const [displaySection, setDisplaySection] = useState(false);
  const [displayGenerateSection, setDisplayGenerateSection] = useState(false);

  useLayoutEffect(() => {
    if(supplierSelection !== null){
      if(supplierSelection.supplierEmail !== null && reportType === "Near Miss"){
        setDisplaySection(true);
      }
    }
  }, [supplierSelection, reportType])

  useLayoutEffect(() => {
    if(isSiteTeam !== null){
      console.log("isSiteTeam: "+isSiteTeam)
      if(isSiteTeam == 'true' && reportType != 'Accident/Injury'){
        console.log("Display Generate Link")
        setDisplayGenerateSection(true);
      }
    }
  }, [isSiteTeam])


  const riddorChange = (e) => {
    setRiddor(e.target.value);
  }

  const sendSupplierEmailChange = (e) => {
    setSendSupplierEmail(e.target.value);
  }

  const generateLinkChange = (e) => {
    setGenerateLink(e.target.value);
  }

  const checkSupplierEmailNeeded = () => {

    if(displaySection && sendSupplierEmail != null ){
      return true;
    }
    else if(displaySection == false && sendSupplierEmail == null){
      return true;
    }
    else if(displaySection && sendSupplierEmail == null){
      return false;
    }


  }
  const checkGenerateLinkNeeded = () => {
    if(displayGenerateSection && generateLink != null ){
      return true;
    }
    else if(displayGenerateSection == false && generateLink == null){
      generateLink = false;
      return true;
    }
    else if(displayGenerateSection && generateLink == null){
      return false;
    }


  }

  useLayoutEffect(() => {
    if(result && imgResult){
      setResult(false);
      setImgResult(false);
      submitAction();
    }
    
  }, [result, imgResult])

  const submit = async e => {

    let checkBool = checkSupplierEmailNeeded();
    let checkGenBool = checkGenerateLinkNeeded();

    if (riddor !== null && checkBool && checkGenBool ) {
      setProgress(true);
      setRiddorText('');
      setError(false);
      setEmailText('');
      setEmailError(false);
      setGenerateLinkText('');
      setGenerateLinkError(false);
      

      if(!checked){
        supplierTextField="";
      }
      else{
        supplierSelection=null;
      }
      setLineProgress(10);

      if (reportType === 'Accident/Injury') {
        observationType = "";
        fuelSpillValue = null;
        measureControlValue = null;
        notes = "";
      }
      else {
        if (observationType !== "Environmental") {
          fuelSpillValue = null;
          measureControlValue = null;
        }
        age = "";
        body_part_injured = "";
        severity_of_injury = "";
        weather = "";
        lighting = "";
        underfoot_condition = "";
        accidentSummary = "";
      }

        setLineProgress(25);

        const bodyObj = JSON.stringify({
          "reportType": reportType,
          "observationType": observationType,
          "fuelSpillage": fuelSpillValue,
          "controlMeasures": measureControlValue,
          "timeDate": timeDate,
          "siteId": siteSelection? siteSelection.id : 0,
          "reportCategory":reportCategory ? reportCategory.categoryName : "",
          "contactNumber": contactNumber,
          "supplierId": supplierSelection ? supplierSelection.id : 0,
          "profession": professionSelection ? professionSelection.professionName : "",
          "workingArea": workingArea,
          "manualSupplier": checked,
          "manualSupplierName": supplierTextField,
          "memberOfThePublic" : memberOfThePublic,
          "age": age,
          "bodyPartInjury": body_part_injured,
          "severityOfInjury": severity_of_injury,
          "weatherCondition": weather,
          "lightingCondition": lighting,
          "underfootCondition": underfoot_condition,
          "whoWitnessed": witnessed,
          "notes": notes,
          "accidentSummary": accidentSummary,
          "nameOfCompleting": nameOfCompleting,
          "namesOfPplInvolved": injuredIndividual ? injuredIndividual.first_name+" "+injuredIndividual.last_name : namesOfPplInvolved,
          "address1":address1,
          "address2":address2,
          "city":city,
          "county":county,
          "postcode":postcode,
          "consentToShare":consentToShare,
          "notOnSite":notOnSite,
          "accidentActionTaken":accidentActionTaken,
          "sendSupplierReport":sendSupplierEmail ? sendSupplierEmail : false,
          "riddor": riddor,
          "hasImages":Array.isArray(file) && file.length,
          "isSiteTeam":isSiteTeam,
          "generateLink": generateLink,
          "createdByAdminId":adminID,
          "isGoodPractice":isGoodPractice
        });
        console.log("generateLink: "+generateLink)
        setLineProgress(35);
        try {
          await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/createObservation',
            requestConfig: {
              headers: {
                'Content-Type': 'application/json',
              },
              data: bodyObj
            }

          })
        } catch (err) {
          console.log(err);

        }
        if(Array.isArray(file) && file.length){
          imageUpload();
          setResult(true);
          setLineProgress(50);

        }
        else{
          setResult(true);
          setImgResult(true);
        }
        


     

    }
    else {
      if (riddor === null) {
        setRiddorText('Please select an option');
        setError(true);
      }
      if(!checkBool){
        setEmailText('Please select an option');
        setEmailError(true);
        
      }
      if(!checkGenBool ){
        setGenerateLinkText('Please select an option');
        setGenerateLinkError(true);
        
      }

    }
  }


  const imageUpload = async e => {
    console.log(imgFile);
    setLineProgress(60);
    try {
      await axiosFetch({
        axiosInstance: axios,
        method: 'post',
        url: '/imageUpload',
        requestConfig: {
          headers: {},
          data:imgFile
        }

      })
      setImgResult(true);
      setLineProgress(65);
    } catch (err) {
      console.log(err);

    }
    setLineProgress(75);
  }

  function submitAction() {
    console.log("RESPONSE: "+response)
    if (response.status === 200) {
      setLineProgress(100);
      setProgress(false);
      clear();
      if(response.reportReviewLink != ""){
        setReportReviewLink(response.reportReviewLink)
        setReportID(response.report_id)
      }
      
      navigate("/success");


    }  
    else {
      console.log(response);
      console.log(error2);

    }

    function clear(){
      setReportType("");
      setObservationType("");
      setFuelSpillValue(null);
      setMeasureControlValue(null);
      setTimeDate(null);
      
      setContactNumber("");
      setSupplierSelection(null);
      setProfessionSelection(null);
      setWorkingArea("");
      setSupplierTextField("");
      setWitnessed("");
      setChecked(false);
      setMemberOfThePublic(false);
      setAge("");
      setBody_part_injured("");
      setSeverity_of_injury("");
      setImgFile();
      setWeather("");
      setLighting("");
      setUnderfoot_condition("");
      setNotes("");
      setAccidentSummary("");
      setNameOfCompleting("");
      setNamesOfPplInvolved("");
      setInjuredIndividual(null);
      setAddress1("");
      setAddress2("");
      setCity("");
      setCounty("");
      setPostcode("");
      setConsentToShare(false);
      setNotOnSite(false);
      setShowNametextField(false);
      setAccidentActionTaken("");
      setRiddor(null);
      setFile();
      setShowTextField(false);
      setSendSupplierEmail(null);
      setReportCategory(null);
      setIsSiteTeam(null);
      setGenerateLink(null);
      setAdminID('');
      setAdminFirstName('');
      setAdminLastName('');
      setIsGoodPractice(null);
    }
  }
  return (

    <>
      <Box className={styles.questionH1Top} >
        <h1>Is this RIDDOR reportable?</h1>
        </Box>
        <Box className={styles.radioGroup}>
        <FormControl sx={{ m: 3 }} error={error} variant="standard">
          <RadioGroup
            row
            aria-labelledby="demo-error-radios"
            name="row-radio-buttons-group"
            value={riddor}
            onChange={riddorChange}
          >
            <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={true} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="Yes" />
            <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={false} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="No" />

          </RadioGroup>
          <FormHelperText>{riddorText}</FormHelperText>
        </FormControl>
        </Box>
        {displaySection ?
        <>
        <Box className={styles.questionH1Top} >
        <h1>Do you want to send a copy of this report to the company involved?</h1>
        </Box>
        <Box className={styles.radioGroup}>
        <FormControl sx={{ m: 3 }} error={emailError} variant="standard">
          <RadioGroup
            row
            aria-labelledby="demo-error-radios"
            name="row-radio-buttons-group"
            value={sendSupplierEmail}
            onChange={sendSupplierEmailChange}
          >
            <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={true} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="Yes" />
            <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={false} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="No" />

          </RadioGroup>
          <FormHelperText>{emailText}</FormHelperText>
        </FormControl>
        </Box>
        </>
        :
        <></>
   }
   {displayGenerateSection ?
        <>
        <Box className={styles.questionH1Top} >
        <h1>Do you want a sharable link to this report, which you can send to a subcontractor to review and action?</h1>
        </Box>
        <Box className={styles.radioGroup}>
        <FormControl sx={{ m: 3 }} error={generateLinkError} variant="standard">
          <RadioGroup
            row
            aria-labelledby="demo-error-radios"
            name="row-radio-buttons-group"
            value={generateLink}
            onChange={generateLinkChange}
          >
            <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={true} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="Yes" />
            <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={false} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="No" />

          </RadioGroup>
          <FormHelperText>{generateLinkText}</FormHelperText>
        </FormControl>
        </Box>
        </>
        :
        <></>
   }
        <Box className={styles.progressBar}>
        {progress && <LinearProgressWithLabel  value={lineProgress} />} 
        
        </Box>

      <Box className={btnStyles.navbuttons} >
              <Button disabled={progress} startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button disabled={progress} startIcon={<UploadIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
          onClick={() => submit()} >Submit</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction disabled={progress} id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction disabled={progress} id="navNextButton" label='Submit' icon={<UploadIcon />} onClick={() => submit()} />
              </BottomNavigation>
          </Box>
    </>
  );
}

